import $ from 'jquery'

let c = 0;
$(".testimonial_dot_container > .testimonial_dot").on('click', function () {
    let s, e, p, v, x;
    s = $(this).attr('data-slider');
    e = $(".testimonial_box_container > div");
    p = $(".testimonial_box_container");
    v = Math.round(p.width() / e.outerWidth());
    if (s === 'previous' && c >= 1) c--;
    else if (s === 'next') {
        if (c >= e.length - v) c = 0; else c++;
    } else return false;
    x = (c * (e.first().outerWidth() + 20));
    e.css('transform', 'translate(' + (-x) + 'px)');
    e.removeClass('active');
    e.eq($(this).index()).addClass('active');
});

/*$('.slider-main').owlCarousel({
    loop: false,
    margin: 10,
    nav: false,
    dots: true,
    items: 4,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
});*/

$(window).scroll(function () {

    if ($(window).scrollTop() >= 200) {

        $('#header').addClass('sticky');

    } else {
        $('#header').removeClass('sticky');
    }


});
